
.h1
    @apply pb-4
    @apply font-bold

    > img
        @apply inline-block
        height: 1em
        width: 1em
        margin: 0 .05em 0 .1em
        vertical-align: -0.1em

    &--multiline
        @apply whitespace-normal

    &:not(.h1--multiline)
        @apply text-ellipsis whitespace-pre overflow-hidden
    
    &:not(.h1--editable)
        @apply select-none        

    &--editable
        @apply p-4
        @apply rounded-md
        @apply border-0
        @apply ring-2 ring-slate-200 bg-white
        @apply transition-all

        &:hover
            @apply bg-slate-100

        &:focus
            @apply bg-slate-100
            @apply shadow-inner
            @apply outline-none ring-4 ring-primary
        
        &::placeholder
            @apply text-slate-400

    &--placeholder
        @apply pt-8
    
.label
    @apply absolute
    @apply top-4 left-4
    @apply opacity-0
    @apply font-bold text-slate-400
    @apply transform scale-150 origin-left
    @apply transition-all    

    &--filled
        @apply block
        @apply top-2
        @apply opacity-100
        @apply scale-100